jQuery(document).ready(function ($) {

    function boughto_tyre_vehicle_search() {
        $('.boughto-tyre-vehicle-search').each(function () {
            var $self = $(this),
                manufacturer = $self.find("select[name='manufacturer']"),
                model = $self.find("select[name='model']"),
                generation = $self.find("select[name='generation']"),
                chassis = $self.find("select[name='chassis']"),
                submit = $self.find("button[type='submit']");

            manufacturer.val(-1);
            model.attr('disabled', 'disabled');
            generation.attr('disabled', 'disabled');
            chassis.attr('disabled', 'disabled');
            submit.attr('disabled', 'disabled');

            manufacturer.on('change', function () {

                model.attr('disabled', 'disabled');
                model.val(-1);
                generation.attr('disabled', 'disabled');
                generation.val(-1);
                chassis.attr('disabled', 'disabled');
                chassis.val(-1);
                submit.attr('disabled', 'disabled');

                if ($(this).val() < 0) {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_models',
                        manufacturer_id: $(this).val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading Models...</option>";
                        model.html(ret);
                        model.attr('disabled', 'disabled');
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='-1'>Model</option>";
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.id + '">' + item.name + '</option>';
                            });
                            model.html(ret);
                            model.closest('.form-group').removeClass('boughto-disabled');
                            model.removeAttr('disabled');
                        } else {
                            var ret = "<option value='-1'>No Models Found</option>";
                            model.html(ret);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var ret = "<option value='-1'>Error Loading Models!</option>";
                        model.html(ret);
                    }
                });
            });

            model.on('change', function () {

                generation.attr('disabled', 'disabled');
                generation.val(-1);
                chassis.attr('disabled', 'disabled');
                chassis.val(-1);
                submit.attr('disabled', 'disabled');

                if ($(this).val() < 0) {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_generations',
                        model_id: $(this).val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading Years...</option>";
                        generation.html(ret);
                        generation.attr('disabled', 'disabled');
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='-1'>Year</option>";
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.id + '">' + item.name + '</option>';
                            });
                            generation.html(ret);
                            generation.closest('.form-group').removeClass('boughto-disabled');
                            generation.removeAttr('disabled');
                        } else {
                            var ret = "<option value='-1'>No Years Found</option>";
                            generation.html(ret);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var ret = "<option value='-1'>Error Loading Years!</option>";
                        generation.html(ret);
                    }
                });
            });

            generation.on('change', function () {

                chassis.attr('disabled', 'disabled');
                chassis.val(-1);
                submit.attr('disabled', 'disabled');

                if ($(this).val() < 0) {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_chassis',
                        generation_id: $(this).val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading Engines...</option>";
                        chassis.html(ret);
                        chassis.attr('disabled', 'disabled');
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='-1'>Engines</option>";
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.id + '">' + item.name + '</option>';
                            });
                            chassis.html(ret);
                            chassis.closest('.form-group').removeClass('boughto-disabled');
                            chassis.removeAttr('disabled');
                        } else {
                            var ret = "<option value='-1'>No Engines Found</option>";
                            chassis.html(ret);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var ret = "<option value='-1'>Error Loading Engines!</option>";
                        chassis.html(ret);
                    }
                });
            });

            chassis.on('change', function () {
                submit.removeAttr('disabled');
            });
        });
    }

    boughto_tyre_vehicle_search();
});