jQuery(document).ready(function ($) {
    $('.boughto .tyre_search_by_vehicle_confirm-0').each(function () {
        var section = $('form.boughto-search.boughto-tyre-vehicle-search-confirm #tyre-section'),
            profile = $('form.boughto-search.boughto-tyre-vehicle-search-confirm #tyre-profile'),
            rim_size = $('form.boughto-search.boughto-tyre-vehicle-search-confirm #tyre-rim');

        $('#boughto_tyre_size_confirm_chassis_search').click(function () {
            var option = $('#boughto_tyre_size_selector').find(":selected");
            section.val(option.data('section'));
            profile.val(option.data('profile'));
            rim_size.val(option.data('rim_size'));
            $('form.boughto-search.boughto-tyre-vehicle-search-confirm').submit();
        });
    });
});