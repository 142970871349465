jQuery(function ($) {
        if ($('.boughto.tyre_search_results').length) {

            var current_request = false;
            getResults();

            function getResults(filters = {}) {
                if (current_request) {
                    return;
                }

                current_request = true;

                $.each(filters, function (key, value) {
                    if (Array.isArray(value)) {
                        new_value = "";
                        $.each(value, function (id, name) {
                            if (new_value != "") {
                                new_value += ',';
                            }
                            new_value += name;
                        });
                        filters[key] = new_value;
                    }
                });

                $('.default_filters').each(function () {
                    if (filters[$(this).attr('name')] === undefined) {
                        filters[$(this).attr('name')] = $(this).val();
                    }
                });

                updateUrlPrams(filters);

                filters['action'] = 'post_get_tyre_results';

                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: filters,
                    dataType: 'json',
                    beforeSend: function () {
                        $('.loading').show();
                    },
                    success: function (response) {

                        if (response.hasOwnProperty('data')) {
                            response = response.data;

                            // results
                            $('.boughto_results_tyres').html(response.results_html);
                            $('.lazy').Lazy({chainable: false});

                            // pagination
                            $('.boughto_results_pagination').html(response.pagination_html);

                            // filters
                            $.each(response.filters, function (name, data) {
                                mobileHtml = desktopHtml = '';

                                $.each(data.data.options, function (key, filter) {
                                    let value = filter.key;

                                    if (filter.value !== undefined) {
                                        value = filter.value;
                                    }

                                    checked = "";
                                    if (data.data.selected + "" === value + "") {
                                        checked = "checked";
                                    }

                                    desktopHtml += '<div class="flex items-center">' +
                                        '<input ' + checked + ' id="filter-' + data.name + '-' + filter.key + '" name="' + data.name + '" value="' + value + '" type="' + data.type + '" class="h-4 w-4 border-gray-300 rounded text-boughto_primary_action_hex focus:ring-boughto_primary_action_hex">' +
                                        '<label for="filter-' + data.name + '-' + filter.key + '" class="ml-3 text-sm text-gray-600">' + filter.key + '</label></div>';

                                    mobileHtml += '<div class="flex items-center">' +
                                        '<input ' + checked + ' id="filter-m-' + data.name + '-' + filter.key + '"  name="' + data.name + '" value="' + value + '" type="' + data.type + '"  class="h-4 w-4 border-gray-300 rounded text-boughto_primary_action_hex focus:ring-boughto_primary_action_hex">' +
                                        '<label for="filter-m-' + data.name + '-' + filter.key + '" class="ml-3 min-w-0 flex-1 text-gray-500">' + filter.key + '</label></div>'

                                });

                                $('.' + data.class + '_desktop').html(desktopHtml);
                                $('.' + data.class + '_mobile').html(mobileHtml);
                            });


                            checkFiltersChanged();
                        } else {
                            // var ret = "<option value='-1'>No " + model.data("name") + " Found</option>";
                            // model.html(ret);
                        }

                        $('.loading').show();
                        current_request = false;
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        $('.loading').hide();
                        current_request = false;

                        // var ret = "<option value='-1'>Error Loading " + model.data("name") + "!</option>";
                        // model.html(ret);
                    }
                });
            }

            function updateUrlPrams(filters) {
                const url = new URL(window.document.URL);
                $.each(filters, function (key, value) {
                    url.searchParams.set(key, value);
                });

                window.history.replaceState(null, "", url.toString());
            }

            function checkFiltersChanged() {
                $('.boughto_tyre_results').each(function () {
                    var $self = $(this);

                    $self.find('.boughto_sort_button').click(function () {
                        $self.find('form.boughto_results_filters').each(function () {
                            updateForm($(this))
                        });
                    })

                    $self.find('form.boughto_results_filters').each(function () {
                        var $form = $(this);
                        var filters = 'input[name="range_name"], input[name="speed_rating"], input[name="wet_grip"], input[name="fuel_efficiency"], input[name="noise_decibel"], input[name="noise_rating"]';

                        $form.find(filters).unbind();
                        $form.find(filters).change(function () {
                            updateForm($form)
                        });
                    });
                });
            }

            function updateForm($form) {
                var range_name = $form.find('input[name="range_name"]:checked'),
                    speed_rating = $form.find('input[name="speed_rating"]:checked'),
                    wet_grip = $form.find('input[name="wet_grip"]:checked'),
                    fuel_efficiency = $form.find('input[name="fuel_efficiency"]:checked'),
                    noise_decibel = $form.find('input[name="noise_decibel"]:checked'),
                    noise_rating = $form.find('input[name="noise_rating"]:checked');

                getResults({
                    range_name: range_name.map(function () {
                        return $(this).val();
                    }).get(),
                    speed_rating: speed_rating.map(function () {
                        return $(this).val();
                    }).get(),
                    wet_grip: wet_grip.map(function () {
                        return $(this).val();
                    }).get(),
                    fuel_efficiency: fuel_efficiency.map(function () {
                        return $(this).val();
                    }).get(),
                    noise_decibel: noise_decibel.map(function () {
                        return $(this).val();
                    }).get(),
                    noise_rating: noise_rating.map(function () {
                        return $(this).val();
                    }).get(),
                });
            }
        }
    }
);