jQuery(document).ready(function ($) {

    function boughto_tyre_search() {
        $('.boughto-tyre-size-search').each(function () {
            var $self = $(this);

            // Init
            boughto_update_tyres($self);

            $self.find('select').change(function () {
                boughto_update_tyres($self);
            });
        })
    }

    // Fetches the drop downs from the API and processes the form
    function boughto_update_tyres($self) {
        var section = $self.find("select[name='section']"),
            profile = $self.find("select[name='profile']"),
            rim_size = $self.find("select[name='rim_size']"),
            load_index = $self.find("select[name='load_index']"),
            speed_rating = $self.find("select[name='speed_rating']"),
            submit = $self.find("button[type='submit']");

        var section_value = 0,
            profile_value = 0,
            rim_size_value = 0,
            load_index_value = 0,
            speed_rating_value = 0;

        jQuery.ajax({
            url: boughto.ajax_url,
            type: 'post',
            data: {
                action: 'post_get_tyre_info',
                section: section.val(),
                profile: profile.val(),
                rim_size: rim_size.val(),
                load_index: load_index.val(),
                speed_rating: speed_rating.val(),
            },
            dataType: 'json',
            beforeSend: function () {
                var ret = "<option value=''>Loading...</option>";

                section_value = section.val();
                profile_value = profile.val();
                rim_size_value = rim_size.val();
                load_index_value = load_index.val();
                speed_rating_value = speed_rating.val();

                if (section_value == "") {
                    section.html(ret);
                }
                if (profile_value == "") {
                    profile.html(ret);
                }
                if (rim_size_value == "") {
                    rim_size.html(ret);
                }
                if (load_index_value == "") {
                    load_index.html(ret);
                }
                if (speed_rating_value == "") {
                    speed_rating.html(ret);
                }

                section.attr('disabled', 'disabled');
                profile.attr('disabled', 'disabled');
                rim_size.attr('disabled', 'disabled');
                load_index.attr('disabled', 'disabled');
                speed_rating.attr('disabled', 'disabled');
                submit.attr('disabled', 'disabled');

            },
            success: function (response) {
                boughto_process_select(section, response['section'], 'Section')
                boughto_process_select(profile, response['profile'], 'Profile')
                boughto_process_select(rim_size, response['rim_size'], 'Rim Size')
                boughto_process_select(load_index, response['load_index'], 'Load Rating')
                boughto_process_select(speed_rating, response['speed_rating'], 'Speed Index')

                section.val(section_value);
                profile.val(profile_value);
                rim_size.val(rim_size_value);
                load_index.val(load_index_value);
                speed_rating.val(speed_rating_value);

                submit.removeAttr('disabled');
            },
            error: function () {
                var ret = "<option value='0'>Error!</option>";

                section.html(ret);
                profile.html(ret);
                rim_size.html(ret);
                load_index.html(ret);
                speed_rating.html(ret);
            }
        });
    }

    // Create the dropdown
    function boughto_process_select(select, data, label) {
        var ret = "<option value=''>Any " + label + "</option>";
        $.each(data, function (key, item) {
            ret += '<option value="' + item + '">' + item + '</option>';
        });

        select.html(ret);
        select.removeAttr('disabled');

    }

    boughto_tyre_search();

})