jQuery(document).ready(function ($) {

    function boughto_wheel_vehicle_search() {
        $('.boughto-wheel-vehicle-search').each(function () {
            var $self = $(this),
                range_id = $self.find("input[name='range_id']"),
                type = $self.find("input[name='type']"),
                manufacturer = $self.find("select[name='manufacturer']"),
                model = $self.find("select[name='model']"),
                generation = $self.find("select[name='generation']"),
                chassis = $self.find("select[name='chassis']"),
                size = $self.find("select[name='diameter']"),
                submit = $self.find("button[type='submit']"),
                status_msgs = $self.find(".boughto-search-status span");

            manufacturer.val(-1);
            model.attr('disabled', 'disabled');
            generation.attr('disabled', 'disabled');
            chassis.attr('disabled', 'disabled');
            size.attr('disabled', 'disabled');
            submit.attr('disabled', 'disabled');

            status_msgs.each(function (i, status_msg) {
                $(status_msg).html("");
            });

            manufacturer.on('change', function () {

                model.attr('disabled', 'disabled');
                model.val(-1);
                generation.attr('disabled', 'disabled');
                generation.val(-1);
                chassis.attr('disabled', 'disabled');
                chassis.val(-1);
                size.attr('disabled', 'disabled');
                size.val('all');
                submit.attr('disabled', 'disabled');

                status_msgs.each(function (i, status_msg) {
                    $(status_msg).html("");
                });

                if ($(this).val() < 0) {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_models',
                        manufacturer_id: $(this).val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading " + model.data("name") + "...</option>";
                        model.html(ret);
                        model.attr('disabled', 'disabled');
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='-1'>" + model.data("name") + "</option>";
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.id + '">' + item.name + '</option>';
                            });
                            model.html(ret);
                            model.closest('.form-group').removeClass('boughto-disabled');
                            model.removeAttr('disabled');
                        } else {
                            var ret = "<option value='-1'>No " + model.data("name") + " Found</option>";
                            model.html(ret);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var ret = "<option value='-1'>Error Loading " + model.data("name") + "!</option>";
                        model.html(ret);
                    }
                });
            });

            model.on('change', function () {

                generation.attr('disabled', 'disabled');
                generation.val(-1);
                chassis.attr('disabled', 'disabled');
                chassis.val(-1);
                size.attr('disabled', 'disabled');
                size.val('all');
                submit.attr('disabled', 'disabled');

                status_msgs.each(function (i, status_msg) {
                    $(status_msg).html("");
                });

                if ($(this).val() < 0) {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_generations',
                        model_id: $(this).val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading " + generation.data("name") + "...</option>";
                        generation.html(ret);
                        generation.attr('disabled', 'disabled');
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='-1'>" + generation.data("name") + "</option>";
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.id + '">' + item.name + '</option>';
                            });
                            generation.html(ret);
                            generation.closest('.form-group').removeClass('boughto-disabled');
                            generation.removeAttr('disabled');
                        } else {
                            var ret = "<option value='-1'>No " + generation.data("name") + " Found</option>";
                            generation.html(ret);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var ret = "<option value='-1'>Error Loading " + generation.data("name") + "!</option>";
                        generation.html(ret);
                    }
                });
            });

            generation.on('change', function () {

                chassis.attr('disabled', 'disabled');
                chassis.val(-1);
                size.attr('disabled', 'disabled');
                size.val('all');
                submit.attr('disabled', 'disabled');

                status_msgs.each(function (i, status_msg) {
                    $(status_msg).html("");
                });

                if ($(this).val() < 0) {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_chassis',
                        generation_id: $(this).val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading " + chassis.data("name") + "...</option>";
                        chassis.html(ret);
                        chassis.attr('disabled', 'disabled');
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='-1'>" + chassis.data("name") + "</option>";
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.id + '">' + item.name + '</option>';
                            });
                            chassis.html(ret);
                            chassis.closest('.form-group').removeClass('boughto-disabled');
                            chassis.removeAttr('disabled');
                        } else {
                            var ret = "<option value='-1'>No " + chassis.data("name") + " Found</option>";
                            chassis.html(ret);
                        }
                    },
                    error: function (xhr, ajaxOptions, thrownError) {
                        var ret = "<option value='-1'>Error Loading " + chassis.data("name") + "!</option>";
                        chassis.html(ret);
                    }
                });
            });

            chassis.on('change', function () {

                size.attr('disabled', 'disabled');
                size.val('all');
                submit.attr('disabled', 'disabled');

                if ($(this).val() < 0 || $(this).val() === "all") {
                    return;
                }
                jQuery.ajax({
                    url: boughto.ajax_url,
                    type: 'post',
                    data: {
                        action: 'post_get_chassis_diameters',
                        chassis_id: $(this).val(),
                        type: type.val(),
                        range_id: range_id.val(),
                    },
                    dataType: 'json',
                    beforeSend: function () {
                        var ret = "<option value='-1'>Loading " + size.data("name") + "...</option>";
                        size.html(ret);
                    },
                    success: function (response) {
                        if (response.hasOwnProperty('data')) {
                            response = response.data;
                            var ret = "<option value='all'>" + size.data("name") + "</option>";
                            ret += '<option value="all">All</option>';
                            $.each(response, function (key, item) {
                                ret += '<option value="' + item.size + '"' + '>' + item.name + '</option>';
                            });
                            size.html(ret);
                            size.closest('.form-group').removeClass('boughto-disabled');
                            size.removeAttr('disabled');
                            submit.removeAttr('disabled');
                        } else {
                            if (type.val() === "check") {
                                var ret = "<option value='-1'>" + size.data("check-failed") + "</option>";
                            } else {
                                var ret = "<option value='-1'>No " + size.data("name") + " Found</option>";
                            }
                            status_msgs.each(function (i, status_msg) {
                                $(status_msg).html($(status_msg).data('check-failed'));
                            });
                            size.html(ret);
                        }
                    }
                });
            });
        });
    }

    boughto_wheel_vehicle_search();

    function boughto_wheel_data_search() {
        $('form.boughto-wheel-data-search').each(function () {
                var $self = $(this);

                $self.submit(function() {
                   $(this).find('select').each(function() {
                       if($(this).val() == 'loading') {
                           $(this).val('');
                       }
                   });
                });

                updateBoughtoWheelDataSearch($self);
                $self.find("select[name='width']")
                    .add($self.find("select[name='diameter']"))
                    .add($self.find("select[name='pcd']"))
                    .add($self.find("select[name='colour']"))
                    .add($self.find("select[name='brand']"))
                    .on('change', function () {
                        selectOnChange = $(this);

                        if (!selectOnChange) {
                            return;
                        }
                        if (selectOnChange == null) {
                            return;
                        }
                        if (!selectOnChange.val()) {
                            return;
                        }
                        if (selectOnChange.val() == 'loading') {
                            return;
                        }
                        updateBoughtoWheelDataSearch($self);
                    });
            }
        );
    }

    function updateBoughtoWheelDataSearch($self) {
        var width = $self.find("select[name='width']"),
            diameter = $self.find("select[name='diameter']"),
            pcd = $self.find("select[name='pcd']"),
            colour = $self.find("select[name='colour']"),
            brand = $self.find("select[name='brand']");

        if (!width || !diameter || !pcd || !colour || !brand) {
            return;
        }

        width_val = width.val() ? width.val() : "";
        if(width_val == 'loading'){
            width_val = "";
        }

        diameter_val = diameter.val() ? diameter.val() : "";
        if(diameter_val == 'loading'){
            diameter_val = "";
        }

        pcd_val = pcd.val() ? pcd.val() : "";
        if(pcd_val == 'loading'){
            pcd_val = "";
        }

        colour_val = colour.val() ? colour.val() : "";
        if(colour_val == 'loading'){
            colour_val = "";
        }

        brand_val = brand.val() ? brand.val() : "";
        if(brand_val == 'loading'){
            brand_val = "";
        }

        jQuery.ajax({
            url: boughto.ajax_url,
            type: 'post',
            data: {
                action: 'post_get_wheels_by_data',
                width: width_val,
                diameter: diameter_val,
                pcd: pcd_val,
                colour: colour_val,
                brand: brand_val,
            },
            dataType: 'json',
            beforeSend: function () {
                data_before_send(width);
                data_before_send(diameter);
                data_before_send(pcd);
                data_before_send(colour);
                data_before_send(brand);

            },
            success: function (response) {
                if (response.success != true) {
                    return;
                }
                data_process_response(width, response.data.filters.width);
                data_process_response(diameter, response.data.filters.diameter);
                data_process_response(pcd, response.data.filters.pcd);
                data_process_response(colour, response.data.filters.colour);
                data_process_response(brand, response.data.filters.brand);
            },
            error: function (xhr, ajaxOptions, thrownError) {
                data_error(width);
                data_error(diameter);
                data_error(pcd);
                data_error(colour);
                data_error(brand);
            }
        });
    }

    function data_before_send(obj) {
        var ret = "<option value='loading'>Loading " + obj.data("name") + "...</option>";
        obj.html(ret);
        obj.attr('disabled', 'disabled');
    }

    function data_error(obj) {
        var ret = "<option value='loading'>Error Loading " + obj.data("name") + "!</option>";
        obj.html(ret);
    }

    function data_process_response(obj, response) {
        if (response.hasOwnProperty('options')) {
            var ret = "<option value='loading'>" + obj.data("name") + "</option>";
            $.each(response.options, function (key, item) {
                selected = response.selected == item.key ? 'selected' : '';
                ret += '<option ' + selected + ' value="' + item.key + '">' + item.key + '</option>';
            });
            obj.html(ret);
            obj.removeAttr('disabled');
        } else {
            var ret = "<option value='loading'>No " + obj.data("name") + " Found</option>";
            obj.html(ret);
        }
    }

    boughto_wheel_data_search();
});